import { languages } from "./locales/use-locale";
import globalStylesHref from "./styles/global.css";
import type {
  LinksFunction,
  LoaderFunction,
  MetaFunction } from
"@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration } from
"@remix-run/react";
import acceptLanguage from "accept-language-parser";
import cookieParser from "cookie";
import { ToastContainer } from "react-toastify";
import toastStyles from "react-toastify/dist/ReactToastify.css";

export const links: LinksFunction = () => {
  return [
  { rel: "stylesheet", href: globalStylesHref },
  { rel: "stylesheet", href: toastStyles },
  // NOTE: Architect deploys the public directory to /_static/
  { rel: "icon", href: "/_static/favicon.ico" }];

};

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "Unified identity - Coreconsulting",
  viewport: "width=device-width,initial-scale=1"
});

export const loader: LoaderFunction = async ({ request }) => {
  const cookies = request.headers.get("Cookie") || "";
  const parsedCookies = cookieParser.parse(cookies);
  const acceptLanguages = acceptLanguage.parse((
    request.headers.get("Accept-Language") as string)
  );

  const preferredLang = parsedCookies.selectedLang;

  if (preferredLang) {
    return { language: preferredLang };
  }

  if (acceptLanguages?.length < 1) return { lang: "en" };

  const browserLanguage = acceptLanguages[0].code;
  const hasLang = languages.includes(browserLanguage);

  return { language: hasLang ? browserLanguage : "en" };
};

export default function App() {
  return (
    <html lang="en" className="h-full">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <ToastContainer />

        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}